import { Controller } from '@hotwired/stimulus'
import Client from 'shopify-buy'

export default class extends Controller {
  static targets = [ "productId", "productTags", "variantPrice", "cartButton",
    "notifyContainer", "notifyForm", "notifyMe", "preorderContainer",
    "notifyName", "notifyEmail", "notifyEmailError", "notifyNameError"
  ]

  initialize() {
    // console.log("products!")

    this.client = Client.buildClient({
      domain: 'shopify.stubborngoods.com',
      storefrontAccessToken: 'dc898d804cbac4f8d7877bb1622d885d'
    })

    this.options = {}
    this.productTags = this.productTagsTarget.value.split(",")

    this.client.product.fetch(this.productId).then((product) => {
      this.product = product
      if (product.variants.length === 1) {
        this.options = product.variants[0].selectedOptions.reduce((m, opt) => {
          m[opt.name] = opt.value
          return m
        }, {})
        // console.log("Init Options", this.options)
      }
      this.addToCartState()
    })
  }

  add(event) {
    event.preventDefault()
    // console.log("Adding")
    console.log("options", this.options)
    console.log("data-options", event.target.getAttribute('data-options'))
    const data = {
      id: event.target.getAttribute('data-id'),
      options: event.target.getAttribute('data-options')
    }
    // console.log(data)

    const label = this.cartButtonTarget.innerHTML;
    this.cartButtonTarget.innerHTML = "Added!"

    window.setTimeout(() => {
      this.cartButtonTarget.innerHTML = label
    }, 2500)

    // $(this.application).trigger("cart:add", data)
    if (window.CustomEvent && typeof window.CustomEvent === 'function') {
      var event = new CustomEvent('cart:add', {detail: data});
    } else {
      var event = document.createEvent('CustomEvent');
      event.initCustomEvent('cart:add', true, true, data);
    }

    window.document.dispatchEvent(event);
    // console.log(`dispatchEvent ${event}`)
    // console.log(event)
  }

  notify(event) {
    event.preventDefault()
    this.showNode(this.notifyFormTarget)
    this.hideNode(this.notifyMeTarget)
  }

  saveNotification(event) {
    event.preventDefault()
    const email = this.notifyEmailTarget.value
    const name = this.notifyNameTarget.value
    const product = this.notifyContainerTarget.getAttribute("data-sku")

    // console.log("Notify", email, name, product)

    this.hideNode(this.notifyEmailErrorTarget)
    this.hideNode(this.notifyNameErrorTarget)
    if (email === null || email === "" || name === null || name === "") {
      if (email === null || email === "") {
        this.showNode(this.notifyEmailErrorTarget)
      }
      if (name === null || name === "") {
        this.showNode(this.notifyNameErrorTarget)
      }
      return
    }

    fetch(`/.netlify/functions/notifyMe?email=${email}&name=${name}&product=${product}`)

    this.notifyMeTarget.innerHTML = "Saved!"
    this.notifyMeTarget.setAttribute('disabled', true)
    this.showNode(this.notifyMeTarget)
    this.hideNode(this.notifyFormTarget)
  }

  enableCart(text = "Add to Cart") {
    this.cartButtonTarget.setAttribute('disabled', false) // HACK FOR IE EDGE
    this.cartButtonTarget.removeAttribute('disabled')
    this.cartButtonTarget.classList.remove('border-gray-400', 'bg-gray-300');
    this.cartButtonTarget.classList.add('border-green-400', 'bg-green-300');
    this.cartButtonTarget.innerHTML = text
  }

  disableCart(text = "Add to Cart") {
    this.cartButtonTarget.setAttribute('disabled', true)
    this.cartButtonTarget.classList.add('border-gray-400', 'bg-gray-300');
    this.cartButtonTarget.classList.remove('border-green-400', 'bg-green-300');
    this.cartButtonTarget.innerHTML = text
  }

  hideNode(node) {
    node.classList.add('hidden');
  }

  showNode(node) {
    node.classList.remove('hidden');
  }

  isPreorder(tags) {
    return this.productTags.some(t => t === 'Preorder')
  }

  addToCartState() {
    const selectedVariant = this.client.product.helpers.variantForOptions(this.product, this.options);
    // console.log("selected variant", selectedVariant)
    // console.log("product", this.product)

    if (selectedVariant) {
      const variableId = selectedVariant.id
      const selectedOptions = selectedVariant.selectedOptions

      this.cartButtonTarget.setAttribute('data-id', variableId)
      this.cartButtonTarget.setAttribute('data-options', JSON.stringify(selectedOptions))
      this.variantPriceTarget.innerHTML = selectedVariant.price

      if (selectedVariant.available) {
        this.enableCart()
        this.hideNode(this.notifyContainerTarget)
        this.hideNode(this.preorderContainerTarget)
      } else {
        this.disableCart()
        if (this.isPreorder()) {
          this.enableCart("Preorder!")
          this.showNode(this.preorderContainerTarget)
        }
        this.notifyContainerTarget.setAttribute('data-sku', selectedVariant.sku)
        this.showNode(this.notifyContainerTarget)
      }
    } else {
      this.disableCart()
      this.hideNode(this.notifyContainerTarget)
    }
  }

  updateOptions(event) {
    event.preventDefault()
    const el = event.target
    const name = el.getAttribute('data-name')
    const value = el.getAttribute('data-value')
    this.options = Object.assign(this.options, {[name]: value})

    this.getAllSiblings(el, (elem) => { return elem.nodeName.toUpperCase() == 'BUTTON'}).map((elem) => {
      elem.classList.add('border-gray-400', 'bg-gray-300')
      elem.classList.remove('border-green-400', 'bg-green-300')
    })

    el.classList.remove('border-gray-400', 'bg-gray-300');
    el.classList.add('border-green-400', 'bg-green-300');

    this.addToCartState()
  }

  getAllSiblings(elem, filter) {
    var sibs = [];
    elem = elem.parentNode.firstChild;
    do {
      if (elem.nodeType === 3) continue; // text node
      if (!filter || filter(elem)) sibs.push(elem);
    } while (elem = elem.nextSibling)
    return sibs;
  }


  get productId() {
    return this.productIdTarget.value
  }
}
