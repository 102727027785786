import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "details", "caret" ]

  toggle () {
    this.detailsTarget.classList.toggle("hidden")

    const rightCarets = this.element.querySelectorAll(".fa-caret-right[data-expand-caret]")
    const downCarets = this.element.querySelectorAll(".fa-caret-down[data-expand-caret]")

    rightCarets.forEach((el) => {
      el.classList.remove("fa-caret-right")
      el.classList.add("fa-caret-down")
    })
    downCarets.forEach((el) => {
      el.classList.add("fa-caret-right")
      el.classList.remove("fa-caret-down")
    })
  }

  cancel(e) {
    e.cancelBubble = true
  }
}
