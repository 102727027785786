import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "box", "tray" ]

  connect() {
    this.imgs = []
    this.element.querySelectorAll("[data-lg-img]").forEach((e) => {
      this.imgs.push(e.dataset.lgImg)
    })

    this.keys = this.keys.bind(this)

    const body = document.querySelector("body")
    body.addEventListener("keyup", this.keys)
  }

  disconnect() {
    const body = document.querySelector("body")
    body.removeEventListener("keyup", this.keys)
  }

  previous(e) {
    e.preventDefault()
    this.currentIdx = (this.currentIdx == 0 ? this.imgs.length : this.currentIdx) - 1
    this.setImage()
  }

  next(e) {
    e.preventDefault()
    this.currentIdx = this.currentIdx == (this.imgs.length - 1) ? 0 : (this.currentIdx + 1)
    this.setImage()
  }

  showBox(e) {
    e.preventDefault()
    this.currentIdx = parseInt(e.currentTarget.dataset.imgIdx)
    this.setImage()
    this.boxTarget.classList.remove("hidden")
  }

  setImage() {
    this.trayTarget.innerHTML = `<img src="${this.imgs[this.currentIdx]}" class="mx-auto max-w-full max-h-full" alt="Product Image"/>`
  }

  hideBox(e) {
    e.preventDefault()
    this.boxTarget.classList.add("hidden")
  }

  keys(e) {
    switch(e.code) {
      case "Escape":
        this.hideBox(e)
        break;
      case "ArrowLeft":
        this.previous(e)
        break;
      case "ArrowRight":
        this.next(e)
        break;
    }
  }
}
