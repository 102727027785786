import { Controller } from '@hotwired/stimulus'
import Client from 'shopify-buy'
import cartSummaryTemplate from '../templates/cart_summary.js'
import cartDetailsTemplate from '../templates/cart_details.js'

export default class extends Controller {
  static targets = [ "summary", "details" ]

  initialize() {
    this.client = Client.buildClient({
      domain: 'shopify.stubborngoods.com',
      storefrontAccessToken: 'dc898d804cbac4f8d7877bb1622d885d'
    })

    const newCart = () => {
      this.client.checkout.create().then((checkout) => {
        // Do something with the checkout
        // console.log("new checkout");
        this.setCheckoutId(checkout.id)
        this.render(checkout)
      });
    }

    if (this.checkoutId) {
      this.client.checkout.fetch(this.checkoutId).then((checkout) => {
        // console.log("existing checkout", checkout);
        if (checkout.completedAt) {
          newCart()
          return
        }
        this.setCheckoutId(checkout.id)
        this.render(checkout)
      }).catch((error) => {
        // console.log(error)
        newCart()
      });
    } else {
      newCart()
    }

    this.addToCart = this.addToCart.bind(this)
  }

  get checkoutId() {
    return window.localStorage.getItem('checkoutId');
  }

  connect() {
    window.document.addEventListener("cart:add", this.addToCart)
  }

  disconnect() {
    window.document.removeEventListener("cart:add", this.addToCart)
  }

  setCheckoutId(id) {
    window.localStorage.setItem('checkoutId', id)
    return id
  }

  addToCart(e) {
    // console.log('adding to cart')
    const props = e.detail

    const cattrs = JSON.parse(props.options).map((h) => {
      return {key: h.name, value: h.value};
    })

    const lineItemsToAdd = [
      {
        variantId: props.id,
        quantity: 1,
        customAttributes: cattrs
      }
    ];

    this.client.checkout.addLineItems(this.checkoutId, lineItemsToAdd).then((checkout) => {
      this.render(checkout)
    });
  }

  decrement(e) {
    e.preventDefault()
    // console.log('dec')
    const lineItemsToUpdate = [{
      id: e.target.parentElement.getAttribute("data-id"),
      quantity: parseInt(e.target.parentElement.getAttribute("data-quantity")) - 1
    }];

    // console.log(lineItemsToUpdate)
    this.client.checkout.updateLineItems(this.checkoutId, lineItemsToUpdate).then((checkout) => {
      this.render(checkout)
    });
  }

  increment(e) {
    e.preventDefault()
    // console.log('inc')
    const lineItemsToUpdate = [{
      id: e.target.parentElement.getAttribute("data-id"),
      quantity: parseInt(e.target.parentElement.getAttribute("data-quantity")) + 1
    }];

    // console.log(lineItemsToUpdate)
    this.client.checkout.updateLineItems(this.checkoutId, lineItemsToUpdate).then((checkout) => {
      this.render(checkout)
    });
  }

  remove(e) {
    e.preventDefault()
    // console.log('rem')
    const lineItemIdsToRemove = [
      e.target.parentElement.getAttribute("data-id")
    ];

    // console.log(lineItemIdsToRemove)
    this.client.checkout.removeLineItems(this.checkoutId, lineItemIdsToRemove).then((checkout) => {
      this.render(checkout)
    });
  }

  render(checkout) {
    // console.log(checkout);
    this.checkout = checkout

    // console.log("ITEMS")
    // console.log(this.checkout.lineItems)
    this.summaryTarget.innerHTML = cartSummaryTemplate.render({
      items: this.checkout.lineItems.reduce((m, item) => { return m + item.quantity }, 0),
      subtotalPrice: this.checkout.subtotalPrice,
      webUrl: this.checkout.webUrl,
      buttonClasses: this.checkoutButtonClasses()

    })
    this.detailsTarget.innerHTML = cartDetailsTemplate.render({
      items: this.checkout.lineItems,
      subtotalPrice: this.checkout.subtotalPrice,
      currency: function(value) { return value.toFixed(2); }
    })
  }

  checkoutButtonClasses() {
    const items = this.checkout.lineItems
    if (items.length > 0) {
      return "border-green-400 bg-green-300 hover:border-green-600 hover:bg-green-500"
    } else {
      return "border-gray-400 bg-gray-300"
    }
  }
}
