const nunjucks = require('nunjucks')

const tmp = `
<i class="fa fa-fw fa-caret-right" data-expand-caret></i>
<i class="fa fa-fw fa-shopping-cart"></i>
{{ items }} Items
- 
\${{ subtotalPrice }}
<a href="{{ webUrl }}" data-action="expand#cancel" class="border shadow {{ buttonClasses }} px-4 rounded-sm text-sm mb-1 float-right">Checkout</button>
`

const cartSummaryTemplate = nunjucks.compile(tmp)

export default cartSummaryTemplate
